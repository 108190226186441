import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-zoom-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './zoom-wrapper.component.html',
})
export class ZoomWrapperComponent {
  @Input() isMobile = false;
  @ViewChild('wrapper') wrapper: ElementRef | undefined;
  minZoom = 0;
  maxZoom = 10;
  zoomPercentStep = 20;
  zoom = 0;

  isFullScreen = false;

  enterFloorplanFullScreen() {
    this.isFullScreen = true;
    this.zoom = 4;
  }

  exitFloorplanFullScreen() {
    if (this.wrapper) {
      this.wrapper.nativeElement.scrollLeft = 0;
    }
    this.zoom = 0;
    this.isFullScreen = false;
  }

  zoomIncrease() {
    this.zoom += 1;
  }

  zoomDecrease() {
    this.zoom -= 1;
  }
}
