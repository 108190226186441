import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SpaceplanModel } from '@models';
import { AppState, updateDeviceData, updateRoom } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { AsyncPipe, NgForOf, NgIf, NgStyle } from '@angular/common';
import { PipesModule } from '@pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeleteSpaceplanComponent } from '@standalone/_spaceplan/delete-spaceplan/delete-spaceplan.component';
import { SpaceplanAttachmentComponent } from '@standalone/_spaceplan/spaceplan-attachment/spaceplan-attachment.component';
import { Content } from '@ngneat/overview';
import { ZoomWrapperComponent } from '@standalone/zoom-wrapper/zoom-wrapper.component';

@Component({
  selector: 'app-spaceplan',
  templateUrl: './spaceplan.component.html',
  standalone: true,
  styles: [
    `
      app-spaceplan-attachment {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
      }
    `,
  ],
  imports: [
    NgForOf,
    PipesModule,
    NgxSkeletonLoaderModule,
    DeleteSpaceplanComponent,
    NgIf,
    AsyncPipe,
    NgStyle,
    SpaceplanAttachmentComponent,
    ZoomWrapperComponent,
  ],
})
export class SpaceplanComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() spaceplan!: SpaceplanModel;
  @Input() currentAttachmentId = '';
  @Input() locationId!: string;
  @Input() isMobile = false;
  @Input() canEdit = false;
  @Input() popover: Content | null = null;
  @ViewChild('container') container!: ElementRef;
  @ViewChild('img') img!: ElementRef;
  clientId: string;
  width = 0;
  height = 0;
  isImgLoaded = false;
  resizeObserver: ResizeObserver | undefined;

  constructor(
    private appService: AppService,
    private store: Store<AppState>,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.clientId = this.appService.currentClient;
  }

  get editAttachmentId() {
    return this.appService.activeAttachmentId;
  }

  ngAfterViewInit() {
    if (this.img?.nativeElement) {
      this.img.nativeElement.onload = () => {
        this.isImgLoaded = true;
      };

      this.resizeObserver = new ResizeObserver(entries => {
        this.width = entries[0].contentRect.width;
        this.height = entries[0].contentRect.height;
        this.cdr.detectChanges();
      });

      this.resizeObserver.observe(this.container.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['spaceplan']?.currentValue?.id !== changes['spaceplan']?.previousValue?.id) {
      this.isImgLoaded = false;
    }
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
    this.resetAttachmentId();
  }

  confirm({ attachmentId, position }: { attachmentId: string; position: string }, isRoomAttachment: boolean) {
    this.resetAttachmentId();
    const dispatchAction = isRoomAttachment
      ? updateRoom({
          locationId: this.locationId,
          spaceId: attachmentId,
          data: { unstructuredDataReference: position },
        })
      : updateDeviceData({
          locationId: this.locationId,
          deviceId: attachmentId,
          data: { unstructuredDataReference: position },
        });

    this.store.dispatch(dispatchAction);
  }

  resetAttachmentId() {
    this.appService.activeAttachmentId = null;
  }
}
